<template>
  <div class=" successBoxPhone" v-if="isMobile">
    <div class="banner">
      <img :src="constUrl+banner" v-if="banner!==''">
    </div>
    <div class="newsCountent">
      <div class="newsTop" v-for="item in dataList" :key="item.id">
        <div class="newBox">
          <el-row :gutter="40" style="width: 100%">
            <el-col :span="12">
              <img :src="constUrl+item.picture">
            </el-col>
            <el-col :span="11">
              <h3>{{item.name}}</h3>
              <p>{{item.detail}}</p>
              <span>{{item.subtitle}}<span @click="toDetails(item.id)" style="cursor: pointer;font-size:2rem;margin-left: 2vw;color: #0d9efa;display: inline-block">了解详情>></span></span>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-button @click="loadMore" v-if="isShow==1" :loading="isLoading">加载更多...</el-button>
      <el-button v-else-if="isShow==2">加载完成</el-button>
      <footer-box v-if="isMobile"   class="footerBoxAll" style="z-index: 999" ></footer-box>
    </div>
  </div>
  <div class="successBox" v-else>
    <div class="banner">
      <img :src="constUrl+banner" v-if="banner!==''">
    </div>
    <div class="newsCountent">
      <div class="newsTop" v-for="item in dataList" :key="item.id">
        <div class="newBox">
          <el-row :gutter="40" style="width: 100%">
            <el-col :span="7">
              <img :src="constUrl+item.picture">
            </el-col>
            <el-col :span="17">
              <h3>{{item.name}}</h3>
              <p>{{item.detail}}</p>
              <span>{{item.subtitle}}<span @click="toDetails(item.id)" style="cursor: pointer;font-size: 16px;margin-left: 20px;color: #0d9efa;display: inline-block">了解详情>></span></span>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-button @click="loadMore" v-if="isShow==1" :loading="isLoading">加载更多...</el-button>
      <el-button v-else-if="isShow==2">加载完成</el-button>
    </div>
  </div>
</template>

<script>
import ItemMixins from "../item.mixins";

export default {
  mixins: [ItemMixins],
  name: 'two',
  data() {
    return {
      isLoading: false,
      isShow: 1,
      banner: '',
      dataList: [],
      form: {
        page: 1,
        pageSize: 5,
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    toDetails(id) {
      this.$router.push({
        path: `/news/detail/${id}`,
      })
    },
    /*加载更多*/
    loadMore() {
      this.form.page += 1
      this.loadData()
    },
    async loadData() {
      this.isLoading = true
      let list = await this.getChildList(this.form.page, this.form.pageSize)
      if (list.length == 0) {
        this.isShow = 2
      } else {
        this.isShow = 1
      }
      list.map((x) => {
        this.dataList.push(x)
      })
      this.isLoading = false
    },
    //获取数据
    async init() {
      await this.getHeadMenuList()
      let item = this.getActiveItem()
      this.banner = item.picture[0]
      this.loadData()
    },
  }
}
</script>
<style scoped lang="less">
  .successBox {
    width: 100vw;

    .el-button {
      background-image: linear-gradient(#43d5fd, #0385ff);
      color: white;
      font-size: 20px;
      font-weight: 600;
      height: 50px;
    }

    .banner {
      width: 100%;
      height: 60vh;
      overflow: hidden;
      margin: auto;

      img {
        width: 100%;
        /*height: 100%;*/
      }
    }

    .newsCountent {
      width: 52%;
      height: 100%;
      margin: auto;
      overflow: auto;
      min-height: 50vh;
      text-align: center;

      /*box-sizing: border-box;*/
      padding-top: 4vh;
      padding-bottom: 6vh;

      .newsTop {
        width: 100%;
        margin-bottom: 20px;
        height: 180px;
        padding-top: 10px;

        .newBox {
          transition: all 0.4s;
          width: 98%;
          height: 100%;
          background: rgba(239, 239, 239, 0.4);
          border-radius: 20px;
          box-sizing: border-box;
          overflow: hidden;
          padding: 15px 20px;


          .el-col-17 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 130px;
          }

          .el-col-7 {
            img {
              border-radius: 10px;
              height: 140px;
              width: 100%;
            }
          }


          h3 {
            font-size: 25px;
            font-weight: 600;
            height: 30px;
            margin-top: 10px;
            color: transparent;
            background-image: linear-gradient(#43d5fd, #0385ff);
            -webkit-background-clip: text;
          }
          p {
            text-align: left;
            width: 90%;
            line-height: 25px;
            height: 50px;
            overflow: hidden;
            color: #555555;
            text-indent: 5%;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
          }

          span {;
          }
        }

      }

      .newBox:hover {
        background: rgba(0, 144, 255, 0.2);
        transform: translateX(2%);
      }

      .newsCenter {
        width: 100%;
        padding-bottom: 4%;

        h3 {
          text-align: center;
          font-size: 1.8rem;
          width: 80%;
          color: transparent;
          margin: 5% auto;
          background-image: linear-gradient(#43d5fd, #0385ff);
          -webkit-background-clip: text;
          font-weight: 600;
        }

        ul {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;


          li {
            width: 23%;
            height: 26vh;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

            div {
              width: 100%;
              height: 16vh;
              overflow: hidden;

              /*background: red;*/

              img {
                width: 100%;
                height: 100%;
                transition: transform 0.5s;
                -webkit-transition: transform 0.5s;
              }

            }

            h4 {
              font-size: 1.4rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
<!--手机样式-->
<style scoped lang="less">
  .successBoxPhone {
    width: 100vw;
    .el-button {
      background-image: linear-gradient(#43d5fd, #0385ff);
      color: white;
      font-size: 20px;
      font-weight: 600;
      height: 50px;
    }
    .banner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: auto;
      img {
        width: 100%;
        /*height: 100%;*/
      }
    }
    .newsCountent {
      width: 90%;
      height: 100%;
      margin: auto;
      overflow: auto;
      min-height: 50vh;
      text-align: center;
      /*box-sizing: border-box;*/
      padding-top: 0vh;
      padding-bottom: 6vh;
      .newsTop {
        width: 100%;
        margin-bottom: 20px;
        height: 100%;
        padding-top: 10px;

        .newBox {
          transition: all 0.4s;
          width: 99%;
          height: 100%;
          background: rgba(239, 239, 239, 0.4);
          border-radius: 2vw;
          box-sizing: border-box;
          overflow: hidden;
          padding: 2vw 3vw;
          /deep/.el-col{
            padding: 0 !important;
          }
          .el-col-11 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
            margin-left: 2vw;
          }

          .el-col-12 {
            img {
              border-radius: 1vw;
              height:100%;
              width: 100%;
            }
          }
          h3 {
            font-size: 4rem;
            font-weight: 600;
            height: 100%;
            margin-top: 0px;
            color: transparent;
            background-image: linear-gradient(#43d5fd, #0385ff);
            -webkit-background-clip: text;
          }
          p {
            text-align: left;
            width: 90%;
            line-height: 25px;
            height:100%;
            overflow: hidden;
            color: #555555;
            text-indent: 5%;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
          }

          span {
            font-size: 3rem;
          }
        }

      }

      .newBox:hover {
        background: rgba(0, 144, 255, 0.2);
        transform: translateX(2%);
      }

      .newsCenter {
        width: 100%;
        padding-bottom: 4%;

        h3 {
          text-align: center;
          font-size: 1.8rem;
          width: 80%;
          color: transparent;
          margin: 5% auto;
          background-image: linear-gradient(#43d5fd, #0385ff);
          -webkit-background-clip: text;
          font-weight: 600;
        }

        ul {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;


          li {
            width: 23%;
            height: 26vh;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

            div {
              width: 100%;
              height: 16vh;
              overflow: hidden;

              /*background: red;*/

              img {
                width: 100%;
                height: 100%;
                transition: transform 0.5s;
                -webkit-transition: transform 0.5s;
              }

            }

            h4 {
              font-size: 1.4rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
